const requiredValidate = value =>
  value ? '' : 'Trường này không được bỏ trống';

const emailValidate = value => {
  if (!value || !value.length) {
    return 'Trường này không được bỏ trống';
  }
  return value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Sai định dạng email'
    : '';
};

const lengthValidate = length => value => {
  return value && value.length > length
    ? ''
    : `Trường này cần từ ${length} ký tự trở lên`;
};

const lengthValidate4 = lengthValidate(4);
const lengthValidate6 = lengthValidate(6);

const passwordValidate = value =>
  !value || value.length < 8 ? 'Mật khẩu phải từ 8 ký tự trở lên' : '';

const rePasswordValidate = (password, rePassword) =>
  password !== rePassword ? 'Xác nhận mật khẩu không đúng' : '';

const validateEmpty = err => value => {
  return value.length === 0 ? err : '';
};

const validateEmail = err => value => {
  const re = /.+@.+\..+/;
  return re.test(value.toLowerCase()) || value.length === 0 ? '' : err;
};

const validatePhoneNumber = err => value => {
  const re = /^\+?(\d.*){6,}$/;
  return re.test(value.toLowerCase()) || value.length === 0 ? '' : err;
};

const validateMatching = err => (value1, value2) => {
  return value1 === value2 ? '' : err;
};

const validateLength8 = err => value => {
  return value.length >= 8 ? '' : err;
};

const validateLength6 = err => value => {
  return value.length >= 6 ? '' : err;
};

const composeValidate = validateFuncs => value => {
  if (typeof validateFuncs === 'function') {
    return validateFuncs(value);
  }
  try {
    for (let i = 0; i < validateFuncs.length; i++) {
      if (validateFuncs[i](value).length > 0) {
        return validateFuncs[i](value);
      }
    }
    return '';
  } catch (error) {
    console.error();
  }
};

export {
  requiredValidate,
  emailValidate,
  passwordValidate,
  rePasswordValidate,
  lengthValidate4,
  lengthValidate6,
  composeValidate,
  validateMatching,
  validatePhoneNumber,
  validateEmail,
  validateLength6,
  validateLength8,
  validateEmpty
};

export const statusArr = {
  1: {
    id: 1,
    label: 'Lưu kho',
    color: '#828282'
  },
  2: {
    id: 2,
    label: 'Kích hoạt',
    color: '#27AE60'
  },
  3: {
    id: 3,
    label: 'Bảo hành',
    color: '#ed7607'
  },
  4: {
    id: 4,
    label: 'Tự do',
    color: '#828282'
  }
};

import { all, put, takeEvery, select } from 'redux-saga/effects';
import _ from 'lodash';
import {
  GET_REGULATORY_AGENCY,
  ADD_REGULATORY_AGENCY,
  DELETE_REGULATORY_AGENCY,
  EDIT_REGULATORY_AGENCY,
  CREATE_TRANSPORT,
  CREATE_JUNCTION,
  CREATE_FIRE_HYDRANT,
  UPDATE_TRANSPORT,
  UPDATE_FIRE_HYDRANT,
  UPDATE_JUNCTION,
  DELETE_TRANSPORT,
  DELETE_FIRE_HYDRANT,
  DELETE_JUNCTION
} from './index';
import { getRegulatoryAgenciesSuccess } from './index';
import {
  fetchRegulatoryAgenciesApi,
  createRegulatoryAgencyApi,
  deleteRegulatoryAgencyApi,
  updateRegulatoryAgencyApi,
  createTransportApi,
  createFireHydrantApi,
  createJunctionApi,
  updateTransportApi,
  updateFireHydrantApi,
  updateJunctionApi,
  deleteTransportApi,
  deleteFireHydrantApi,
  deleteJunctionApi
} from '../../../api/regulatoryAgencyApi';
import { constants } from '../../../containers/ToastNotification';
import { showToast } from '../notification/index';
import { getToken } from '../auth/index';
import { apiHandleError } from '../../sagas/homeSagas';

export function* getRegulatoryAgenciesSaga(action) {
  const token = yield select(getToken);
  const res = yield fetchRegulatoryAgenciesApi(token);
  if (_.get(res, 'data.status') === 'success') {
    const { data } = res.data;
    const regulatoryAgencies = data.regulatoryAgencies.map(item => ({
      ...item,
      username: _.get(item, 'users[0].username', '')
    }));
    const { total } = data.meta;
    yield put(getRegulatoryAgenciesSuccess(regulatoryAgencies, total));
  } else {
    yield put(getRegulatoryAgenciesSuccess([], 0));
    yield put(apiHandleError(res));
  }
}

function* editRASaga(action) {
  const { data } = action.payload;
  const token = yield select(getToken);
  const res = yield updateRegulatoryAgencyApi(token, data);
  if (_.get(res, 'data.status') === 'success') {
    const toast = {
      message: 'Sửa cơ quan quản lý thành công',
      action: 'Dismiss',
      type: constants.SUCCESS
    };
    yield put(showToast(toast));
    yield getRegulatoryAgenciesSaga();
  } else {
    yield put(apiHandleError(res));
  }
}

function* addRASaga(action) {
  const { data } = action.payload;
  const token = yield select(getToken);
  const res = yield createRegulatoryAgencyApi(token, data);
  if (_.get(res, 'data.status') === 'success') {
    const toast = {
      message: 'Thêm cơ quan quản lý thành công',
      action: 'Dismiss',
      type: constants.SUCCESS
    };
    yield put(showToast(toast));
    yield getRegulatoryAgenciesSaga();
  } else {
    yield put(apiHandleError(res));
  }
}

function* deleteRASaga(action) {
  const { ids } = action.payload;
  const token = yield select(getToken);
  const res = yield deleteRegulatoryAgencyApi(token, ids);
  if (_.get(res, 'data.status') === 'success') {
    const toast = {
      message: 'Xóa cơ quan quản lý thành công',
      action: 'Dismiss',
      type: constants.SUCCESS
    };
    yield put(showToast(toast));
    yield getRegulatoryAgenciesSaga();
  } else {
    yield put(apiHandleError(res));
  }
}

function* createTransportSaga(action) {
  const { data } = action.payload;
  const token = yield select(getToken);
  const res = yield createTransportApi(token, data);
  if (_.get(res, 'data.status') === 'success') {
    const toast = {
      message: 'Thêm phương tiện thành công',
      action: 'Dismiss',
      type: constants.SUCCESS
    };
    yield put(showToast(toast));
    yield getRegulatoryAgenciesSaga();
  } else {
    yield put(apiHandleError(res));
  }
}

function* updateTransportSaga(action) {
  const { data } = action.payload;
  const token = yield select(getToken);
  const res = yield updateTransportApi(token, data);
  if (_.get(res, 'data.status') === 'success') {
    const toast = {
      message: 'Sửa phương tiện thành công',
      action: 'Dismiss',
      type: constants.SUCCESS
    };
    yield put(showToast(toast));
    yield getRegulatoryAgenciesSaga();
  } else {
    yield put(apiHandleError(res));
  }
}

function* createFireHydrantSaga(action) {
  const { data } = action.payload;
  const token = yield select(getToken);
  const res = yield createFireHydrantApi(token, data);
  if (_.get(res, 'data.status') === 'success') {
    const toast = {
      message: 'Thêm trụ cứu hỏa thành công',
      action: 'Dismiss',
      type: constants.SUCCESS
    };
    yield put(showToast(toast));
    yield getRegulatoryAgenciesSaga();
  } else {
    yield put(apiHandleError(res));
  }
}

function* updateFireHydrantSaga(action) {
  const { data } = action.payload;
  const token = yield select(getToken);
  const res = yield updateFireHydrantApi(token, data);
  if (_.get(res, 'data.status') === 'success') {
    const toast = {
      message: 'Sửa trụ cứu hỏa thành công',
      action: 'Dismiss',
      type: constants.SUCCESS
    };
    yield put(showToast(toast));
    yield getRegulatoryAgenciesSaga();
  } else {
    yield put(apiHandleError(res));
  }
}

function* createJunctionSaga(action) {
  const { data } = action.payload;
  const token = yield select(getToken);
  const res = yield createJunctionApi(token, data);
  if (_.get(res, 'data.status') === 'success') {
    const toast = {
      message: 'Thêm giao lộ thành công',
      action: 'Dismiss',
      type: constants.SUCCESS
    };
    yield put(showToast(toast));
    yield getRegulatoryAgenciesSaga();
  } else {
    yield put(apiHandleError(res));
  }
}

function* updateJunctionSaga(action) {
  const { data } = action.payload;
  const token = yield select(getToken);
  const res = yield updateJunctionApi(token, data);
  if (_.get(res, 'data.status') === 'success') {
    const toast = {
      message: 'Sửa giao lộ thành công',
      action: 'Dismiss',
      type: constants.SUCCESS
    };
    yield put(showToast(toast));
    yield getRegulatoryAgenciesSaga();
  } else {
    yield put(apiHandleError(res));
  }
}

function* deleteTransportSaga(action) {
  const { ids } = action.payload;
  const token = yield select(getToken);
  const res = yield deleteTransportApi(token, ids);
  if (_.get(res, 'data.status') === 'success') {
    const toast = {
      message: 'Xóa phương tiện thành công',
      action: 'Dismiss',
      type: constants.SUCCESS
    };
    yield put(showToast(toast));
    yield getRegulatoryAgenciesSaga();
  } else {
    yield put(apiHandleError(res));
  }
}

function* deleteFireHydrantSaga(action) {
  const { ids } = action.payload;
  const token = yield select(getToken);
  const res = yield deleteFireHydrantApi(token, ids);
  if (_.get(res, 'data.status') === 'success') {
    const toast = {
      message: 'Xóa trụ cứu hỏa thành công',
      action: 'Dismiss',
      type: constants.SUCCESS
    };
    yield put(showToast(toast));
    yield getRegulatoryAgenciesSaga();
  } else {
    yield put(apiHandleError(res));
  }
}

function* deleteJunctionSaga(action) {
  const { ids } = action.payload;
  const token = yield select(getToken);
  const res = yield deleteJunctionApi(token, ids);
  if (_.get(res, 'data.status') === 'success') {
    const toast = {
      message: 'Xóa giao lộ thành công',
      action: 'Dismiss',
      type: constants.SUCCESS
    };
    yield put(showToast(toast));
    yield getRegulatoryAgenciesSaga();
  } else {
    yield put(apiHandleError(res));
  }
}

export default function* regulatoryAgenciesSagas() {
  yield all([
    takeEvery(GET_REGULATORY_AGENCY, getRegulatoryAgenciesSaga),
    takeEvery(ADD_REGULATORY_AGENCY, addRASaga),
    takeEvery(EDIT_REGULATORY_AGENCY, editRASaga),
    takeEvery(DELETE_REGULATORY_AGENCY, deleteRASaga),
    takeEvery(CREATE_TRANSPORT, createTransportSaga),
    takeEvery(CREATE_FIRE_HYDRANT, createFireHydrantSaga),
    takeEvery(CREATE_JUNCTION, createJunctionSaga),
    takeEvery(UPDATE_TRANSPORT, updateTransportSaga),
    takeEvery(UPDATE_FIRE_HYDRANT, updateFireHydrantSaga),
    takeEvery(UPDATE_JUNCTION, updateJunctionSaga),
    takeEvery(DELETE_TRANSPORT, deleteTransportSaga),
    takeEvery(DELETE_FIRE_HYDRANT, deleteFireHydrantSaga),
    takeEvery(DELETE_JUNCTION, deleteJunctionSaga)
  ]);
}

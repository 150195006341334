import { all, put, takeEvery, select } from 'redux-saga/effects';
import _ from 'lodash';
import moment from 'moment';
import {
  GET_NOTIFICATION_LIST_SAGA,
  SUBCRIBE_FCM_SAGA,
  UNSUBCRIBE_FCM_SAGA,
  RA_SUBCRIBE_FCM_SAGA,
  RA_UNSUBCRIBE_FCM_SAGA,
  ADD_NOTIFICATION_SAGA,
  READ_NOTIDICATION_SAGA,
  READ_NOTIDICATION_ALL_SAGA,
  GET_MORE_NOTIFICATION,
  addNotification as addNotificationAction,
  getNotificationList,
  getNotificationListSaga,
  getMoreNotificationSuccess
} from './index';
import {
  getNotificationApi,
  subCloudMessApi,
  unSubCloudMessApi,
  raEnSubCloudMessApi,
  raSubCloudMessApi,
  seenNotification,
  seenAllNotification
} from '../../../api/notificationApi';
import { guid } from '../../../helper/util';
import { getToken, getRoles, getCurrentUser } from '../auth/index';

function* getNotification(action) {
  const { agencyId } = action.payload;
  const token = yield select(getToken);
  const roles = yield select(getRoles);
  const user = yield select(getCurrentUser);
  const role = _.get(roles, '[0].name');
  const ra_id = _.get(user, 'regulatory_agency_id');
  const res = yield getNotificationApi(
    role,
    token,
    role === 'regulatory_agency_admin' ? ra_id : agencyId
  );
  if (res.data && res.data.ok) {
    const notificationList = _.get(res, 'data.data', []);
    const readed = notificationList.filter(item => item.read === true).length;
    const unreaded = notificationList.filter(item => item.read !== true).length;
    const notificationListReprocess = notificationList.map(item => ({
      ...item,
      id: `${item.id}-${guid()}`,
      seen: item.read,
      type: _.get(item, 'data.type', 'complete'),
      notificationTitle: item.title,
      notificationBody: item.body,
      productType: _.get(item, 'data.product_type', ''),
      statusDescription: moment(new Date(item.timestamp * 1000)).format(
        'HH:mm:ss DD-MM-YYYY'
      )
    }));
    yield put(getNotificationList(notificationListReprocess, readed, unreaded));
  }
}

function* getMoreNotiSaga(action) {
  const { agencyId, timestamp } = action.payload;
  const token = yield select(getToken);
  const roles = yield select(getRoles);
  const role = _.get(roles, '[0].name');
  const res = yield getNotificationApi(role, token, agencyId, timestamp);
  if (res.data && res.data.ok) {
    const notificationList = _.get(res, 'data.data', []);
    const readed = notificationList.filter(item => item.read === true).length;
    const unreaded = notificationList.filter(item => item.read !== true).length;
    const notificationListReprocess = notificationList.map(item => ({
      ...item,
      id: `${item.id}-${guid()}`,
      seen: item.read,
      type: _.get(item, 'data.type', 'complete'),
      notificationTitle: item.title,
      notificationBody: item.body,
      productType: _.get(item, 'data.product_type', ''),
      statusDescription: moment(new Date(item.timestamp * 1000)).format(
        'HH:mm:ss DD-MM-YYYY'
      )
    }));
    yield put(
      getMoreNotificationSuccess(notificationListReprocess, readed, unreaded)
    );
  }
}

function* subcribeCloudMessage(action) {
  const { token, agencyId } = action.payload;
  const access_token = yield select(getToken);

  yield subCloudMessApi(access_token, agencyId, token);
}

function* unSubcribeCloudMessage(action) {
  const { token, agencyId } = action.payload;
  const access_token = yield select(getToken);

  yield unSubCloudMessApi(access_token, agencyId, token);
}
function* raSubcribeCloudMessage(action) {
  const { token, agencyId } = action.payload;
  const access_token = yield select(getToken);

  yield raSubCloudMessApi(access_token, agencyId, token);
}

function* raUnSubcribeCloudMessage(action) {
  const { token, agencyId } = action.payload;
  const access_token = yield select(getToken);

  yield raEnSubCloudMessApi(access_token, agencyId, token);
}

function* addNotification(action) {
  const { agencyId, notification: messageBody } = action.payload;
  const notificationReprocess = {
    ...messageBody,
    id: `a1-${guid()}`,
    body: messageBody.notification.body,
    title: messageBody.notification.title,
    seen: false,
    read: false,
    type: 'warning',
    notificationTitle: messageBody.notification.title,
    notificationBody: messageBody.notification.body,
    statusDescription: moment(Date.now()).format('DD-MM-YYYY HH:mm:ss')
  };
  yield put(addNotificationAction(notificationReprocess));
  yield put(getNotificationListSaga(agencyId));
}

function* clickNotifi(action) {
  const roles = yield select(getRoles);
  const role = _.get(roles, '[0].name');
  const { agencyId, timestamp } = action.payload;
  const token = yield select(getToken);
  const user = yield select(getCurrentUser);
  const ra_id = _.get(user, 'regulatory_agency_id');

  yield seenNotification(
    role,
    token,
    role === 'regulatory_agency_admin' ? ra_id : agencyId,
    timestamp
  );

  yield put(getNotificationListSaga(agencyId));
}

function* readallNoti(action) {
  const roles = yield select(getRoles);
  const role = _.get(roles, '[0].name');
  const { agencyId } = action.payload;
  const user = yield select(getCurrentUser);
  const ra_id = _.get(user, 'regulatory_agency_id');
  const token = yield select(getToken);

  yield seenAllNotification(
    role,
    token,
    role === 'regulatory_agency_admin' ? ra_id : agencyId
  );
  yield put(getNotificationListSaga(agencyId));
}

export default function* notificationSagas() {
  yield all([
    takeEvery(GET_NOTIFICATION_LIST_SAGA, getNotification),
    takeEvery(SUBCRIBE_FCM_SAGA, subcribeCloudMessage),
    takeEvery(UNSUBCRIBE_FCM_SAGA, unSubcribeCloudMessage),
    takeEvery(RA_SUBCRIBE_FCM_SAGA, raSubcribeCloudMessage),
    takeEvery(RA_UNSUBCRIBE_FCM_SAGA, raUnSubcribeCloudMessage),
    takeEvery(ADD_NOTIFICATION_SAGA, addNotification),
    takeEvery(READ_NOTIDICATION_SAGA, clickNotifi),
    takeEvery(READ_NOTIDICATION_ALL_SAGA, readallNoti),
    takeEvery(GET_MORE_NOTIFICATION, getMoreNotiSaga)
  ]);
}

import { all, put, takeEvery, select } from 'redux-saga/effects';
import _ from 'lodash';
import { fetchListsApi, fetchListTypesApi } from '../../../api/categoryApi';
import { getToken } from '../auth/index';
import { apiHandleError } from '../../sagas/homeSagas';
import {
  GET_LISTS,
  GET_LIST_TYPES,
  getListsSuccess,
  getListtypesSuccess
} from './index';

export function* getListsSaga(action) {
  const token = yield select(getToken);
  const res = yield fetchListsApi(token);
  if (_.get(res, 'data.status') === 'success') {
    const { data } = res.data;
    yield put(getListsSuccess(data));
  } else {
    yield put(getListsSuccess([], 0));
    yield put(apiHandleError(res));
  }
}

export function* getListtypesSaga(action) {
  const token = yield select(getToken);
  const res = yield fetchListTypesApi(token);
  if (_.get(res, 'data.status') === 'success') {
    const { data } = res.data;
    yield put(getListtypesSuccess(data));
  } else {
    yield put(getListtypesSuccess([], 0));
    yield put(apiHandleError(res));
  }
}

export default function* categoriesSagas() {
  yield all([takeEvery(GET_LISTS, getListsSaga)]);
  yield all([takeEvery(GET_LIST_TYPES, getListtypesSaga)]);
}

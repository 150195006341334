import { createReducer } from '../../reducers/helper';

export const GET_LISTS = 'GET_LISTS';
export const GET_LISTS_SUCCESS = 'GET_LISTS_SUCCESS';
export const GET_LIST_TYPES = 'GET_LIST_TYPES';
export const GET_LIST_TYPES_SUCCESS = 'GET_LIST_TYPES_SUCCESS';

const defaultState = {
  lists: {},
  listtypes: {},
  fetched: false,
  fetching: false,
  processing: false
};
const reducer = createReducer(defaultState, {
  [GET_LISTS_SUCCESS]: (state, action) => ({
    ...state,
    fetched: true,
    fetching: false,
    lists: {
      lists: action.payload.lists,
      total: action.payload.meta.total
    }
  }),
  [GET_LIST_TYPES_SUCCESS]: (state, action) => ({
    ...state,
    fetched: true,
    fetching: false,
    listtypes: {
      listtypes: action.payload.listtypes,
      total: action.payload.meta.total
    }
  })
});

export const getLists = () => ({
  type: GET_LISTS
});

export const getListsSuccess = data => ({
  type: GET_LISTS_SUCCESS,
  payload: data
});

export const getListtypes = () => ({
  type: GET_LIST_TYPES
});

export const getListtypesSuccess = data => ({
  type: GET_LIST_TYPES_SUCCESS,
  payload: data
});

// export const getAgencies = state => state.agencies.agencies;

export default reducer;

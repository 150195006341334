import axios from 'axios';

const baseApi = (token, agency_id) => {
  return axios.create({
    baseURL: `https://sys.vncss.net/vncss/api/v1/agencies/${agency_id}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  });
};

const raBaseApi = (token, id) => {
  return axios.create({
    baseURL: `https://sys.vncss.net/vncss/api/v1/regulatories/${id}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  });
};

export const activeOfficeGatewayApi = (token, agency_id, data) =>
  baseApi(token, agency_id)
    .post(`/gateways`, data)
    .then(res => res)
    .catch(err => ({
      ...err,
      err: true
    }));
export const updateInfoActiveOfficeGatewayApi = (
  token,
  agency_id,
  gateway_id,
  data
) =>
  baseApi(token, agency_id)
    .put(`/gateways/${gateway_id}`, data)
    .then(res => res)
    .catch(err => ({
      ...err,
      err: true
    }));

export const activePVNNodeApi = (token, agency_id, gateway_id, data) =>
  baseApi(token, agency_id)
    .post(`/gateways/${gateway_id}/nodes`, data)
    .then(res => res)
    .catch(err => ({
      ...err,
      err: true
    }));

export const deactivePVNNodeApi = (token, agency_id, gateway_id, data) =>
  baseApi(token, agency_id)
    .delete(
      `/gateways/${gateway_id}/nodes/${data.node_id}?user_id=${data.user_id}`
    )
    .then(res => res)
    .catch(err => ({
      ...err,
      err: true
    }));

export const pingNodeApi = (token, data) =>
  baseApi(token, data.agency_id)
    .post(`/gateways/${data.gateway_id}/nodes/${data.node_id}/pings`)
    .then(res => res)
    .catch(err => ({
      ...err,
      err: true
    }));

export const callSmsApi = (token, data) =>
  raBaseApi(token, data.raId)
    .post(`/gateways/${data.gwId}/callsms`, {
      phonenumbers: [data.phoneNumber]
    })
    .then(res => res)
    .catch(err => ({
      ...err,
      err: true
    }));

export const getImageATM = (token, agency_id, gateway_id) =>
  baseApi(token, agency_id)
    .get(`/gateways/${gateway_id}/images`)
    .then(res => res)
    .catch(err => ({
      ...err,
      err: true
    }));

export const addGatewayContact = (token, data) =>
  baseApi(token, data.agencyId)
    .post(`/gateways/${data.gatewayId}/contacts`, {
      name: data.name,
      email: data.email
    })
    .then(res => res)
    .catch(err => ({
      ...err,
      err: true
    }));

export const deleteGatewayContact = (token, data) =>
  baseApi(token, data.agencyId)
    .delete(`/gateways/${data.gatewayId}/contacts/${data.contactId}`)
    .then(res => res)
    .catch(err => ({
      ...err,
      err: true
    }));

export const handleTestingModeApi = (token, data) =>
  baseApi(token, data.agencyId)
    .post(`gateways/${data.gatewayId}/testing`, { enable: data.enable })
    .then(res => res)
    .catch(err => ({
      ...err,
      err: true
    }));

export const blockGatewayApi = (token, data) =>
  baseApi(token, data.agencyId)
    .post(`gateways/${data.gatewayId}/blocking`, { blocking: data.blocking })
    .then(res => res)
    .catch(err => ({
      ...err,
      err: true
    }));

export const configNodeApi = (token, agency_id, gateway_id, node_id, data) =>
  baseApi(token, agency_id)
    .post(`/gateways/${gateway_id}/nodes/${node_id}/configs`, data)
    .then(res => res)
    .catch(err => ({
      ...err,
      err: true
    }));

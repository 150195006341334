import { createReducer } from '../../reducers/helper';

export const GET_REGULATORY_AGENCY = 'GET_REGULATORY_AGENCY';
export const GET_REGULATORY_AGENCY_SUCCESS = 'GET_REGULATORY_AGENCY_SUCCESS';
export const EDIT_REGULATORY_AGENCY = 'EDIT_AGEDIT_REGULATORY_AGENCYENCY';
export const EDIT_REGULATORY_AGENCY_SUCCESS =
  'EDIT_AGEDIT_REGULATORY_AGENCYENCY';
export const ADD_REGULATORY_AGENCY_SUCCESS = 'ADD_REGULATORY_AGENCY_SUCCESS';
export const ADD_REGULATORY_AGENCY = 'ADD_REGULATORY_AGENCY';
export const DELETE_REGULATORY_AGENCY = 'DELETE_REGULATORY_AGENCY';
export const DELETE_REGULATORY_AGENCY_SAGA = 'DELETE_REGULATORY_AGENCY_SAGA';
export const PROCESSED_REGULATORY_AGENCY = 'PROCESSED_REGULATORY_AGENCY';
export const PROCESSING_REGULATORY_AGENCY = 'PROCESSING_REGULATORY_AGENCY';
export const FETCHING_REGULATORY_AGENCY = 'FETCHING_REGULATORY_AGENCY';
export const CREATE_TRANSPORT = 'CREATE_TRANSPORT';
export const UPDATE_TRANSPORT = 'UPDATE_TRANSPORT';
export const DELETE_TRANSPORT = 'DELETE_TRANSPORT';
export const CREATE_FIRE_HYDRANT = 'CREATE_FIRE_HYDRANT';
export const UPDATE_FIRE_HYDRANT = 'UPDATE_FIRE_HYDRANT';
export const DELETE_FIRE_HYDRANT = 'DELETE_FIRE_HYDRANT';
export const CREATE_JUNCTION = 'CREATE_JUNCTION';
export const UPDATE_JUNCTION = 'UPDATE_JUNCTION';
export const DELETE_JUNCTION = 'DELETE_JUNCTION';

const defaultState = {
  regulatoryAgencies: [],
  fetched: false,
  fetching: false,
  processing: false,
  total: 0
};
const accountReducer = createReducer(defaultState, {
  [GET_REGULATORY_AGENCY_SUCCESS]: (state, action) => ({
    ...state,
    fetched: true,
    fetching: false,
    regulatoryAgencies: action.payload.regulatoryAgencies,
    total: action.payload.total
  }),
  [GET_REGULATORY_AGENCY]: (state, action) => ({
    ...state,
    fetching: true
  }),
  [PROCESSING_REGULATORY_AGENCY]: (state, action) => ({
    ...state,
    processing: true
  }),
  [PROCESSED_REGULATORY_AGENCY]: (state, action) => ({
    ...state,
    processing: false
  }),
  [FETCHING_REGULATORY_AGENCY]: (state, action) => ({
    ...state,
    fetching: true
  })
});

export const getRegulatoryAgencies = (limit, offset, orderData) => ({
  type: GET_REGULATORY_AGENCY,
  payload: {
    limit,
    offset,
    orderData
  }
});

export const getRegulatoryAgenciesSuccess = (regulatoryAgencies, total) => ({
  type: GET_REGULATORY_AGENCY_SUCCESS,
  payload: {
    regulatoryAgencies,
    total
  }
});

export const editRegulatoryAgency = data => ({
  type: EDIT_REGULATORY_AGENCY,
  payload: {
    data
  }
});

export const editRegulatoryAgencySuccess = data => ({
  type: EDIT_REGULATORY_AGENCY_SUCCESS,
  payload: {
    data
  }
});

export const addRegulatoryAgency = data => ({
  type: ADD_REGULATORY_AGENCY,
  payload: {
    data
  }
});

export const addRegulatoryAgencySuccess = agency => ({
  type: ADD_REGULATORY_AGENCY_SUCCESS,
  payload: {
    agency
  }
});

export const deleteAgencySaga = ids => ({
  type: DELETE_REGULATORY_AGENCY,
  payload: {
    ids
  }
});

export const createTransport = data => ({
  type: CREATE_TRANSPORT,
  payload: {
    data
  }
});

export const createFireHydrant = data => ({
  type: CREATE_FIRE_HYDRANT,
  payload: {
    data
  }
});

export const createJunction = data => ({
  type: CREATE_JUNCTION,
  payload: {
    data
  }
});

export const updateTransport = data => ({
  type: UPDATE_TRANSPORT,
  payload: {
    data
  }
});

export const updateFireHydrant = data => ({
  type: UPDATE_FIRE_HYDRANT,
  payload: {
    data
  }
});

export const updateJunction = data => ({
  type: UPDATE_JUNCTION,
  payload: {
    data
  }
});

export const deleteTransport = ids => ({
  type: DELETE_TRANSPORT,
  payload: {
    ids
  }
});

export const deleteFireHydrant = ids => ({
  type: DELETE_FIRE_HYDRANT,
  payload: {
    ids
  }
});

export const deleteJunction = ids => ({
  type: DELETE_JUNCTION,
  payload: {
    ids
  }
});

export const getAgencies = state => state.regulatoryAgencies.regulatoryAgencies;

export default accountReducer;

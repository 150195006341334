import * as firebase from 'firebase/app';
import 'firebase/messaging';

const initializedFirebaseApp = firebase.initializeApp({
  apiKey: 'AIzaSyBrjFP5uNVeGqVZL-R-UTCtwXvBus_GbcA',
  authDomain: 'vncss-7c78c.firebaseapp.com',
  databaseURL: 'https://vncss-7c78c.firebaseio.com',
  projectId: 'vncss-7c78c',
  storageBucket: 'vncss-7c78c.appspot.com',
  messagingSenderId: '522606716293',
  appId: '1:522606716293:web:3978955311b0e6c72d90ec',
  measurementId: 'G-CPKRQ1YJX8'
});

const messaging = initializedFirebaseApp.messaging();

messaging.usePublicVapidKey(
  'BCt4vC2LRnOetoTVJ_Bb--gzya6WSiDTQpmVFKNCFxkn4Cf2iqgK2KSe0mnle_8Ff9xrjQC5RqAm68isZPAe2ZQ'
);

export { messaging };

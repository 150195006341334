export default {
  icon_error: require('./images/icon_error.svg'),
  icon_check_circle: require('./images/icon_check_circle.svg'),
  error_loading: require('./images/error-loading.svg'),
  door_sensor: require('./images/door.svg'),
  gas_sensor: require('./images/gas.svg'),
  heat_sensor: require('./images/heat.svg'),
  ic_vibration_move_sensor: require('./images/ic_vibration_move.svg'), //cam bien dich chuyen
  move_sensor: require('./images/move-sensor.svg'), // cam bien chuyen dong
  remote_sos_sensor: require('./images/remote-sos.svg'), // remove sos
  heat_humid_sensor: require('./images/nhietdo-doam.svg'),
  smoke_sensor: require('./images/smoke.svg'),
  smoke_heat_sensor: require('./images/smoke-heat.svg'),
  speaker_sensor: require('./images/speaker.svg'), // loa den bao dong
  impact_sensor: require('./images/va-dap.svg'),
  power_on: require('./images/power-on.svg'),
  power_off: require('./images/power-off.svg'),
  power_percent: require('./images/renewable-energy-battery-leaf.svg'),
  power_low_percent: require('./images/enery_low_battery.svg'),
  power_on_dot: require('./images/power-on-dot.svg'),
  power_off_dot: require('./images/power-off-dot.svg'),
  status_on: require('./images/status-on.svg'),
  status_off: require('./images/status-off.svg'),
  power_100: require('./images/power-100.svg'),
  power_75: require('./images/power-75.svg'),
  power_50: require('./images/power-50.svg'),
  power_25: require('./images/power-25.svg'),
  power_10: require('./images/power-10.svg'),
  spark: require('./images/spark.svg'), //tia lua,
  uploadIcon: require('./images/upload.svg'),
  ASBL1: require('./images/speaker.svg'),
  SSM: require('./images/speaker.svg'),
  SSM1: require('./images/speaker.svg'),
  RCB4: require('./images/remote-sos.svg'),
  RSBE: require('./images/remote-sos.svg'),
  RSBR: require('./images/remote-sos.svg'),
  SRB: require('./images/remote-sos.svg'),
  SRB1: require('./images/remote-sos.svg'),
  SDB: require('./images/door.svg'),
  SFDB: require('./images/spark.svg'),
  FM: require('./images/spark.svg'),
  FM1: require('./images/spark.svg'),
  SGB: require('./images/gas.svg'),
  SHB: require('./images/heat.svg'),
  SPB: require('./images/move-sensor.svg'),
  SSB: require('./images/smoke-heat.svg'),
  SB: require('./images/smoke-heat.svg'),
  SB1: require('./images/smoke-heat.svg'),
  SSHB: require('./images/smoke-heat.svg'),
  STHB: require('./images/nhietdo-doam.svg'),
  SVB: require('./images/va-dap.svg'),
  SMB: require('./images/ic_vibration_move.svg'), //cam bien dich chuyen
  alertImage: require('./images/alert.svg'),
  A01: require('../asset/icons/building.svg'),
  A4E: require('./images/atm.png'),
  bao_chay: require('./images/bao_chay.png'),
  ZONE: require('./images/zone.svg')
};

import moment from 'moment';
import { baseUrl } from '../api/baseApi';

export function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return `${s4()}-${s4()}-${s4()}`;
}

export function getMinutesDiffNow(timestamp) {
  const now = new moment(Date.now());
  const nodeStateTime = new moment(new Date(timestamp * 1000));
  return moment.duration(now.diff(nodeStateTime)).as('minutes');
}

export function timestampToDate(timestamp) {
  const date = new moment(new Date(timestamp * 1000));
  return date.format('HH:mm DD-MM-YYYY');
}

export function timestampToDateTime(timestamp) {
  const date = new moment(new Date(timestamp * 1000));
  return date.format('DD/MM/YYYY HH:mm');
}

export function isObjectEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key) && obj[key]) return false;
  }
  return true;
}

export const getAllListByListtypeCode = (code, lists = [], listtypes = []) => {
  let listsByListtye = [];
  const listype = listtypes.find(listtype => listtype.code === code);
  if (listype) {
    listsByListtye = lists.filter(list => list.list_type_id === listype.id);
  }
  return listsByListtye;
};

export const getPublicUrl = type => {
  return `${baseUrl}/storage/${type}`;
};

export const downloadCSV = (csv, filename) => {
  const link = document.createElement('a');
  link.setAttribute(
    'href',
    'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(csv)
  );
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const escapeDangerousCSVCharacters = data => {
  if (typeof data === 'string') {
    // Places single quote before the appearance of dangerous characters if they
    // are the first in the data string.
    return data.replace(/^\+|^/g, `$&`);
  }
  return data;
};

export const replaceDoubleQuoteInString = columnData =>
  typeof columnData === 'string' ? columnData.replace(/"/g, '""') : columnData;

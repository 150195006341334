import baseApi from './baseApi';

const includeParams =
  'includes[]=transports&includes[]=junctions&includes[]=fire_hydrants';
export const fetchRegulatoryAgenciesApi = token => {
  return baseApi(token)
    .get(
      `/regulatory-agencies?sort[0][key]=created&sort[0][direction]=DESC&${includeParams}`
    )
    .then(res => res)
    .catch(err => ({
      ...err,
      err: true
    }));
};

export const fetchRegulatoryAgencyApi = (token, id) => {
  return baseApi(token)
    .get(`/regulatory-agencies/${id}&${includeParams}`)
    .then(res => res)
    .catch(err => ({
      ...err,
      err: true
    }));
};

export const createRegulatoryAgencyApi = (token, data) => {
  const payload = {
    regulatory_agency: {
      ...data
    }
  };
  return baseApi(token)
    .post('/regulatory-agencies', payload)
    .then(res => res)
    .catch(err => ({
      ...err,
      err: true
    }));
};

export const updateRegulatoryAgencyApi = (token, data) => {
  const { id } = data;
  const payload = {
    regulatory_agency: {
      ...data
    }
  };
  return baseApi(token)
    .put(`/regulatory-agencies/${id}`, payload)
    .then(res => res)
    .catch(err => ({
      ...err,
      err: true
    }));
};

export const deleteRegulatoryAgencyApi = (token, ids) => {
  return baseApi(token)
    .delete(`/regulatory-agencies/${ids}}`)
    .then(res => res)
    .catch(err => ({
      ...err,
      err: true
    }));
};

export const createTransportApi = (token, data) => {
  const payload = {
    transport: {
      ...data
    }
  };
  return baseApi(token)
    .post('/transports', payload)
    .then(res => res)
    .catch(err => ({
      ...err,
      err: true
    }));
};

export const updateTransportApi = (token, data) => {
  const { id } = data;
  const payload = {
    transport: {
      ...data
    }
  };
  return baseApi(token)
    .put(`/transports/${id}`, payload)
    .then(res => res)
    .catch(err => ({
      ...err,
      err: true
    }));
};

export const deleteTransportApi = (token, ids) => {
  return baseApi(token)
    .delete(`/transports/${ids}`)
    .then(res => res)
    .catch(err => ({
      ...err,
      err: true
    }));
};

export const createFireHydrantApi = (token, data) => {
  const payload = {
    fire_hydrant: {
      ...data
    }
  };
  return baseApi(token)
    .post('/firehydrants', payload)
    .then(res => res)
    .catch(err => ({
      ...err,
      err: true
    }));
};

export const updateFireHydrantApi = (token, data) => {
  const { id } = data;
  const payload = {
    fire_hydrant: {
      ...data
    }
  };
  return baseApi(token)
    .put(`/firehydrants/${id}`, payload)
    .then(res => res)
    .catch(err => ({
      ...err,
      err: true
    }));
};

export const deleteFireHydrantApi = (token, ids) => {
  return baseApi(token)
    .delete(`/firehydrants/${ids}`)
    .then(res => res)
    .catch(err => ({
      ...err,
      err: true
    }));
};

export const createJunctionApi = (token, data) => {
  const payload = {
    junction: {
      ...data
    }
  };
  return baseApi(token)
    .post('/junctions', payload)
    .then(res => res)
    .catch(err => ({
      ...err,
      err: true
    }));
};

export const updateJunctionApi = (token, data) => {
  const { id } = data;
  const payload = {
    junction: {
      ...data
    }
  };
  return baseApi(token)
    .put(`/junctions/${id}`, payload)
    .then(res => res)
    .catch(err => ({
      ...err,
      err: true
    }));
};

export const deleteJunctionApi = (token, ids) => {
  return baseApi(token)
    .delete(`/junctions/${ids}`)
    .then(res => res)
    .catch(err => ({
      ...err,
      err: true
    }));
};

import axios from 'axios';

const baseApi = (token, agency_id) => {
  return axios.create({
    baseURL: `https://sys.vncss.net/vncss/api/v1/agencies/${agency_id}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  });
};
const raBaseApi = (token, id) => {
  return axios.create({
    baseURL: `https://sys.vncss.net/vncss/api/v1/regulatories/${id}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  });
};
export const subCloudMessApi = (token, agency_id, fcm_token) =>
  baseApi(token, agency_id)
    .post('/fcm/subscribe', {
      token: fcm_token
    })
    .then(res => res)
    .catch(err => ({
      ...err,
      err: true
    }));
export const unSubCloudMessApi = (token, agency_id, fcm_token) =>
  baseApi(token, agency_id)
    .post('/fcm/unsubscribe', {
      token: fcm_token
    })
    .then(res => res)
    .catch(err => ({
      ...err,
      err: true
    }));
export const raSubCloudMessApi = (token, id, fcm_token) =>
  raBaseApi(token, id)
    .post('/fcm/subscribe', {
      token: fcm_token
    })
    .then(res => res)
    .catch(err => ({
      ...err,
      err: true
    }));
export const raEnSubCloudMessApi = (token, id, fcm_token) =>
  raBaseApi(token, id)
    .post('/fcm/unsubscribe', {
      token: fcm_token
    })
    .then(res => res)
    .catch(err => ({
      ...err,
      err: true
    }));
export const getNotificationApi = (
  type = 'agency',
  token,
  agency_id,
  timestamp
) =>
  type === 'regulatory_agency_admin'
    ? raBaseApi(token, agency_id)
        .get(
          `/notifications${timestamp ? '?id=a1&timestamp=' + timestamp : ''}`
        )
        .then(res => res)
        .catch(err => ({
          ...err,
          err: true
        }))
    : baseApi(token, agency_id)
        .get(
          `/notifications${timestamp ? '?id=a1&timestamp=' + timestamp : ''}`
        )
        .then(res => res)
        .catch(err => ({
          ...err,
          err: true
        }));

export const seenNotification = (
  type = 'agency',
  token,
  agency_id,
  timestamp
) =>
  type === 'regulatory_agency_admin'
    ? raBaseApi(token, agency_id)
        .post(`/notifications/read`, {
          timestamp: timestamp,
          type: 'one'
        })
        .then(res => res)
        .catch(err => ({
          ...err,
          err: true
        }))
    : baseApi(token, agency_id)
        .post(`/notifications/read`, {
          timestamp: timestamp,
          type: 'one'
        })
        .then(res => res)
        .catch(err => ({
          ...err,
          err: true
        }));

export const seenAllNotification = (
  type = 'agency',
  token,
  agency_id,
  timestamp
) =>
  type === 'regulatory_agency_admin'
    ? raBaseApi(token, agency_id)
        .post(`/notifications/read`, {
          type: 'all'
        })
        .then(res => res)
        .catch(err => ({
          ...err,
          err: true
        }))
    : baseApi(token, agency_id)
        .post(`/notifications/read`, {
          type: 'all'
        })
        .then(res => res)
        .catch(err => ({
          ...err,
          err: true
        }));

export const handleAlertApi = (token, agency_id, gatewayImei) =>
  baseApi(token, agency_id)
    .post(`/notifications/handle`, {
      gateway_serial: gatewayImei
    })
    .then(res => res)
    .catch(err => ({
      ...err,
      err: true
    }));

export const getGatewayLogsApi = (token, agency_id, gatewayImei) =>
  baseApi(token, agency_id)
    .get('/gateway/actionlogs?gateway_serial=' + gatewayImei)
    .then(res => res)
    .catch(err => ({
      ...err,
      err: true
    }));

import baseApi from './baseApi';

export const fetchListsApi = token => {
  return baseApi(token)
    .get(`/lists?sort[0][key]=code&sort[0][direction]=ASC`)
    .then(res => res)
    .catch(err => ({
      ...err,
      err: true
    }));
};

export const fetchListTypesApi = token => {
  return baseApi(token)
    .get(`/listtypes?sort[0][key]=code&sort[0][direction]=ASC`)
    .then(res => res)
    .catch(err => ({
      ...err,
      err: true
    }));
};
